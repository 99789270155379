import React from "react";
import style from "./Footer.module.scss";
import logo from "../../Assets/WhatsApp_Image_2022-05-20_at_10.16.02_PM-removebg-preview.png";

const Footer = () => {
  return (
    <>
      <div className="py-5" style={{ background: "#ECEFF1" }}>
        <div className="container">
          <div className="row g-3">
            <div className="col-12 col-md-6 col-lg-2 d-flex align-items-center">
              <img className="w-100" src={logo} alt="" />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="card" style={{ height: "100%" }}>
                <div className="card-body ">
                  <h5>
                    <b>Canada</b>
                  </h5>
                  <div className="row mb-3">
                    <div className="col-2 d-flex align-items-center">
                      <img
                        src="https://img.icons8.com/fluency/512/enterprise-resource-planning.png"
                        alt=""
                        className="w-100 my-auto"
                      />
                    </div>
                    <div className="col-10 d-flex align-items-center">
                      <p className="my-auto">GO FRONT PRIVATE LTD.</p>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-2 d-flex align-items-center">
                      <img
                        src="https://img.icons8.com/color/512/marker.png"
                        alt=""
                        className="w-100 my-auto"
                      />
                    </div>
                    <div className="col-10 d-flex align-items-center">
                      <p className="my-auto">
                        4346 Trail Blazer Way Mississauga ON L5R 0C3
                      </p>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-2 d-flex align-items-center">
                      <img
                        src="https://img.icons8.com/fluency/512/related-companies.png"
                        alt=""
                        className="w-100 my-auto"
                      />
                    </div>
                    <div className="col-10 d-flex align-items-center">
                      <p className="my-auto">Corporation Number: 1445683-1</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="card" style={{ height: "100%" }}>
                <div className="card-body">
                  <h5>
                    <b>USA</b>
                  </h5>
                  <div className="row">
                    <div className="col-2 d-flex align-items-center">
                      <img
                        src="https://img.icons8.com/fluency/512/enterprise-resource-planning.png"
                        alt=""
                        className="w-100 my-auto"
                      />
                    </div>
                    <div className="col-10 d-flex align-items-center">
                      <p className="my-auto">GO FRONT LLC</p>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-2">
                      <img
                        src="https://img.icons8.com/color/512/marker.png"
                        alt=""
                        className="w-100 my-auto"
                      />
                    </div>
                    <div className="col-10">
                      <p className="my-auto">
                        2604 WHITTIER PL WILMINGTON DE 19808
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <img
                        src="https://img.icons8.com/color/512/collaborator-female.png"
                        alt=""
                        className="w-100 my-auto"
                      />
                    </div>
                    <div className="col-10">
                      <p className="my-auto">
                        <p>EIN: 36-5042638</p>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-2">
              <div className="card" style={{ height: "100%" }}>
                <div className="card-body">
                  <h5>
                    <b>Contact Us</b>
                  </h5>
                  <div className="row g-3">
                    <div className="col-auto">
                      <p className="my-auto">Email :</p>
                    </div>
                    <div className="col-auto">
                      <a
                        href="mailto:hello@trackoncanada.com"
                        className="my-auto"
                        style={{
                          textDecoration: "underline",
                          color: "#0288D1",
                        }}>
                        <p className="my-auto">hello@trackoncanada.com</p>
                      </a>
                    </div>
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-4">
                          <a
                            href="https://medium.com/@trackoncanada"
                            target="_blank"
                            style={{
                              textDecoration: "underline",
                              color: "#0288D1",
                            }}>
                            <img
                              src="https://img.icons8.com/glyph-neue/512/medium-logo.png"
                              alt=""
                              className="w-100"
                            />
                          </a>
                        </div>
                        <div className="col-4">
                          <a
                            target="_blank"
                            href="https://twitter.com/Trackoncanada"
                            style={{
                              textDecoration: "underline",
                              color: "#0288D1",
                            }}>
                            <img
                              src="https://img.icons8.com/fluency/512/twitter.png"
                              alt=""
                              className="w-100"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 bg-dark text-light py-3 text-center ">
        <p className="my-auto"> © Trackon Canada, all rights reserved</p>
      </div>
    </>
  );
};

export default Footer;
