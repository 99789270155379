import React from "react";
import contact from "../Assets/Contact.jpg";
import style from "./Home.module.scss";
import logo from "../Assets/WhatsApp_Image_2022-05-20_at_10.16.02_PM-removebg-preview.png";
const ContactUs = () => {
  return (
    <div className={style.MainContainer}>
      <div className={style.ImageDashBoard}>
        <img src={contact} alt="" />
        <h1>CONTACT US</h1>
      </div>

      <div className={style.Container}>
        <div className={style.row}>
          <div className={style.Content}>
            <div className={style.contentContainer}>
              <div className="card shadow-sm mb-3">
                <div className="card-body">
                  <h4>
                    <b>Canada</b>
                  </h4>
                  <h1>Company: GO FRONT PRIVATE LTD.</h1>
                  <h1>Address: 4346 Trail Blazer Way Mississauga ON L5R 0C3</h1>
                  <h1>Corporation Number: 1445683-1</h1>
                </div>
              </div>
              <div className="card shadow-sm mb-3">
                <div className="card-body">
                  <h4>
                    <b>USA</b>
                  </h4>
                  <h1>Company: GO FRONT LLC</h1>
                  <h1>Address: 2604 WHITTIER PL WILMINGTON DE 19808</h1>
                  <h1>EIN number : 36-5042638</h1>
                </div>
              </div>
              <div className="card shadow-sm mb-3">
                <div className="card-body">
                  <h4>
                    <b>Contact Us</b>
                  </h4>
                  <div className="row g-3">
                    <div className="col-auto ">
                      <h1 className="my-auto">Contact Email :</h1>
                    </div>
                    <div className="col-auto">
                      <a
                        href="mailto:hello@trackoncanada.com"
                        className="my-auto"
                        style={{
                          textDecoration: "underline",
                          color: "#0288D1",
                        }}>
                        <h1 className="my-auto">hello@trackoncanada.com</h1>
                      </a>
                    </div>
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-auto d-flex">
                          <h1 className="my-auto">Visit us also at :</h1>
                        </div>
                        <div className="col-1">
                          <a
                            href="https://medium.com/@trackoncanada"
                            target="_blank"
                            style={{
                              textDecoration: "underline",
                              color: "#0288D1",
                            }}>
                            <img
                              src="https://img.icons8.com/glyph-neue/512/medium-logo.png"
                              alt=""
                              className="w-100"
                            />
                          </a>
                        </div>
                        <div className="col-1">
                          <a
                            target="_blank"
                            href="https://twitter.com/Trackoncanada"
                            style={{
                              textDecoration: "underline",
                              color: "#0288D1",
                            }}>
                            <img
                              src="https://img.icons8.com/fluency/512/twitter.png"
                              alt=""
                              className="w-100"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.socialsContainer}></div>
          </div>
          <div className={style.ImageContainer}>
            <img src={logo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
